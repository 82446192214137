<template>
    <div class="container">
        <div class="px-4 mb-5">
            <h1 class="mt-3">Meu Perfil</h1>
        </div>
        <div class="card p-5 m-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 flex-between">
                        <h2>Informações Pessoais</h2>
                        <router-link class="btn rounded-pill btn-dark-blue btn-editar" :to="{ name: 'Atores.Edicao', params: { id:  this.$route.params.id } }">Editar</router-link>
                    </div>
                    <div class="col-2">
                        <img v-if="registry.foto_perfil !== 'Nenhuma informação cadastrada'" :src="registry.foto_perfil" class="img-perfil" alt="">
                        <img v-if="registry.foto_perfil === 'Nenhuma informação cadastrada'" src="../../assets/logo-letra-branca-fundo-azul.png" class="img-perfil" alt="">
                    </div>
                    <div class="col-8  d-flex justify-content-center">
                        <div class="row">
                            <table v-if="registry !== null">
                                <tr>
                                    <th>Tipo do Ator</th>
                                    <td>{{registry.tipo}}</td>
                                </tr>
                                <tr>
                                    <th>Nome completo</th>
                                    <td>{{registry.nome_do_ator}}</td>
                                </tr>
                                <tr>
                                    <th>Telefone</th>
                                    <td>{{registry.telefone}}</td>
                                </tr>
                                <tr>
                                    <th>CPF</th>
                                    <td>{{registry.cpf_cnpj}}</td>
                                </tr>
                                <tr>
                                    <th>Email</th>
                                    <td>{{registry.email}}</td>
                                </tr>
                            </table>
                            <hr>
                            <table>
                                <tr>
                                    <th>Facebook</th>
                                    <td>{{registry.url_facebook}}</td>
                                </tr>
                                <tr>
                                    <th>Linkedin</th>
                                    <td>{{registry.url_linkedin}}</td>
                                </tr>
                                <tr>
                                    <th>Instagram</th>
                                    <td>{{registry.url_instagram}}</td>
                                </tr>
                                <tr>
                                    <th>Outro</th>
                                    <td>{{registry.url_outra_midia}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card p-5 m-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 flex-between">
                        <h2>Informações Profissionais</h2>
                        <router-link class="btn rounded-pill btn-dark-blue btn-editar" :to="{ name: 'Atores.Edicao', params: { id:  this.$route.params.id } }">Editar</router-link>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-8 d-flex justify-content-center">
                        <div class="row">
                            <table v-if="registry !== null">
                                <tr>
                                    <th>Empresa ou organização em que atua</th>
                                    <td>{{registry.organizacao_atual}}</td>
                                </tr>
                                <tr>
                                    <th>Estado</th>
                                    <td>{{registry.estado}}</td>
                                </tr>
                                <tr>
                                    <th>Departamento ou área</th>
                                    <td>{{registry.departamento_area}}</td>
                                </tr>
                                <tr>
                                    <th>Cargo</th>
                                    <td>{{registry.cargo_pessoa_fisica}}</td>
                                </tr>
                                <tr>
                                    <th>Atribuições</th>
                                    <td>{{registry.atribuicoes}}</td>
                                </tr>
                                <tr>
                                    <th>Campos de Atuação</th>
                                    <td>{{registry.campos_de_atuacao}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card p-5 m-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 flex-between">
                        <h2>Relação com o IU</h2>
                        <router-link class="btn rounded-pill btn-dark-blue btn-editar" :to="{ name: 'Atores.Edicao', params: { id:  this.$route.params.id } }">Editar</router-link>
                    </div>
                    <div class="col-2"></div>
                    <div class="col-8 d-flex justify-content-center">
                        <div class="row">
                            <table v-if="registry !== null">
                                <tr>
                                    <th>Relação com o Instituto Unibanco</th>
                                    <td>{{registry.relacao_unibanco}}</td>
                                </tr>
                                <tr>
                                    <th>Relação com o CdG</th>
                                    <td>{{registry.relacao_cdg}}</td>
                                </tr>
                                <tr>
                                    <th>Funções no Programa</th>
                                    <td>{{registry.funcao_programa}}</td>
                                </tr>
                                <tr>
                                    <th>Responsabilidades e atribuições</th>
                                    <td>{{registry.responsabilidade_no_programa}}</td>
                                </tr>
                                <tr>
                                    <th>Poder</th>
                                    <td>{{registry.grau_de_poder}}</td>
                                </tr>
                                <tr>
                                    <th>Interesse</th>
                                    <td>{{registry.grau_de_interesse}}</td>
                                </tr>
                                <tr>
                                    <th>Campos e incidência e articulação</th>
                                    <td>{{registry.campos_incidencias_e_articulacoes}}</td>
                                </tr>
                                <tr>
                                    <th>Potencialidades</th>
                                    <td>{{registry.potencialidades}}</td>
                                </tr>
                                <tr>
                                    <th>Avaliação do perfil</th>
                                    <td>{{registry.avalicao_de_perfil}}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card p-5 m-5">
            <div class="container">
                <div class="row">
                    <div class="col-12 flex-between">
                        <h2>Minhas associações</h2>
                        <router-link class="btn rounded-pill btn-dark-blue btn-editar" :to="{ name: 'Atores.Edicao', params: { id:  this.$route.params.id } }">Editar</router-link>
                    </div>
                </div>
            </div>
            <div class="container_cards">
                <div v-for="actor of registry.lista_atores_tipo" :key="actor.id" :class="{ 'clickable' : withCallback }">
                    <div class="card-img-text align-images">
                        <div>
                            <img v-if="actor.foto_perfil !== null" :src="actor.foto_perfil" class="img_assoc" alt="">
                            <img v-if="actor.foto_perfil === null" src="../../assets/logo-letra-branca-fundo-azul.png" class="img_assoc" alt="">
                        </div>
                        <br>
                        {{ actor.nome_do_ator }}
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="container pt-5">
                    <div class="col-12 d-flex justify-content-center">
                        <router-link :to="{ name: 'Livre.Visualizacao', params: { id:  this.$route.params.id } }">
                            <button class="btn btn-secondary-action px-4 py-2 rounded-pill">Ver todas conexões</button>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { AtoresRepository } from '../../libs/repositories';

export default {
  name: 'MeuperfilVisualizacao',
  data () {
    return {
      registry: []
    };
  },
  async created () {
    const id = +this.$route.params.id;

    const registry = await AtoresRepository.getById(id);

    /* Esse foreach abaixo, troca o boolean por string,
    pq essa bosta não reconhece na
    interpolação o valor null não sei pq,
    de vez em quando funciona normal,
    de vez em quando da erro */
    Object.keys(registry).forEach(value => {
      if (registry[value] === null) {
        registry[value] = 'Nenhuma informação cadastrada';
      }
    });
    /* esquema pra deixar só 4 registros e ver o resto na outra tela */
    Object.keys(registry).forEach(value => {
      registry.lista_atores_tipo = registry.lista_atores_tipo.slice(0, 4);
    });
    if (registry) {
    //   registry.lista_atores_tipo.slice(0, 2);
      this.registry = registry;
    }
    // console.log(this.registry);
  }
};

</script>
<style scoped>
    .btn-secondary-action{
        border: 1px solid #055B94;
        background: transparent;
        color: #055B94;
    }
    .container_cards{
        display: grid;
        grid-template-columns: 25% 25% 25% 25%;
    }
    .img_assoc{
        border-radius: 100%;
        width: 120px;
        height: 120px;
    }
    .card-img-text{
        text-align: center;
    }
    .flex-between{
        justify-content: space-between !important;
        display: flex;
        margin-bottom: 40px;
    }
    .btn-dark-blue{
        background-color: #2C3A65;
        color: white;
    }
    .btn-editar{
        width:104px;
        line-height: 2;
    }
    .img-perfil{
        width: 135px;
        height: 135px;
        border-radius: 100%;
    }
    .card{
        border: 0px;
    }
    h2{
        font-size: 18px;
        border-bottom: 4px solid #0A85CA;
    }
    table{
        font-size: 14px;
    }
    th {
        text-align: right;
        padding-right: 15px;
        width: 50%;
        padding-bottom: 16px;
    }
    td {
        text-align: left;
        padding-left: 15px;
        color: #868D96;
        width: 50%;
        padding-bottom: 16px;
    }
    hr{
       background-color: #707070;
    }
</style>
